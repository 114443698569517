export default {
  colors: {
    primary: '#0A709C',
    secondary: '#BED733',
    tertiary: '#004655',
    dark: '#202E39',
    light: '#F6F6F6',
    warning: '#EE8201',
    error: '#EA5E65',
    success: '#00B1AA',
  },
  prominent: {
    backgroundColor: 'tertiary',
    action: 'secondary',
  },
  fonts: {
    headings: {
      family: '"Ubuntu", sans-serif',
      lg: {
        size: [40, 60],
        lineHeight: [42, 62],
        weight: 300,
      },
      md: {
        size: [36, 48],
        lineHeight: [38, 50],
        weight: 300,
      },
      sm: {
        size: [28, 36],
        lineHeight: [30, 38],
        weight: 300,
      },
    },
    subheadings: {
      family: '"Ubuntu", sans-serif',
      lg: {
        size: [22, 28],
        lineHeight: [24, 36],
        weight: 500,
      },
      md: {
        size: [20, 24],
        lineHeight: [24, 32],
        weight: 500,
      },
      sm: {
        size: [18, 20],
        lineHeight: [24, 28],
        weight: 500,
      },
    },
    body: {
      family: '"Ubuntu", sans-serif',
      lg: {
        size: 18,
        lineHeight: 24,
      },
      md: {
        size: 16,
        lineHeight: 22,
      },
      sm: {
        size: 14,
        lineHeight: 22,
      },
      xs: {
        size: 12,
        lineHeight: 16,
      },
      xxs: {
        size: 10,
        lineHeight: 12,
      },
    },
  },
  spacing: {
    min: '4px',
    xxs: '8px',
    xs: '12px',
    sm: '16px',
    md: '24px',
    lg: '32px',
    xl: '48px',
    xxl: '64px',
    max: '88px',
  },
}
