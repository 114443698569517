module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://www.viasatsavings.com"},
    },{
      plugin: require('../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"analyticsId":"GTM-MNGTTGF","brandTokens":["VSAT"],"mapiBrandToken":"VSAT","siteName":"viasatsavings","alternateName":"viasatsavings.com","siteURL":"https://www.viasatsavings.com","defaultTitleTemplate":"","defaultPhone":"8556955538","phoneSymbol":"-","defaultPromoCode":"120798","smartyStreetsWebsiteKey":"17515620751423239","addressQualificationCode":"att-saraplus","vwoProjectID":"894940"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"viasatsavings","short_name":"viasatsavings","start_url":"/","background_color":"#0057B8","theme_color":"#0057B8","icon":"src/images/favicon.png","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e91373db3571de7a4ba6a873fedada75"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
